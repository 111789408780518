import classNames from 'classnames'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from 'components/Accordion'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Markdown from 'components/Markdown'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import Page2 from 'templates/Page2'
import headerImage from './images/header.jpg'

type Props = {
  categories?: unknown
  data?: unknown
} & Component

export default function Faq({ categories, className, data }: Props) {
  const globalContext = useGlobalContext()

  return (
    <Page2
      title="Frequently asked questions"
      image={headerImage}
      className={classNames('Faq', className)}>
      <Head
        title="FAQ"
        description="Frequently asked questions about Bold like ‘What is Bold?’, ‘How do I sign up?’, ‘How do I log in?’, and ‘Is Bold for me?’"
      />
      {categories ? (
        Object.keys(data)
          .sort()
          .map((groupKey) => {
            const group = data[groupKey]
            const category = categories.nodes.find((item) => item.frontmatter.title === groupKey)

            if (category?.frontmatter.requiresAuth && !globalContext.isAuthed) return null

            return (
              <Row key={groupKey}>
                <Heading levelStyle={4}>{groupKey}</Heading>
                <Accordion>
                  {group.map((item) => {
                    if (item.requiresAuth && !globalContext.isAuthed) return null

                    return (
                      <AccordionItem key={item.id}>
                        <AccordionItemTitle>
                          <Text element="h3" size="large" weight="bold">
                            {item.frontmatter.title}
                          </Text>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                          <Markdown>{item.body}</Markdown>
                        </AccordionItemBody>
                      </AccordionItem>
                    )
                  })}
                </Accordion>
              </Row>
            )
          })
      ) : (
        <Accordion>
          {data.map((item) => {
            if (item.requiresAuth && !globalContext.isAuthed) return null

            return (
              <AccordionItem key={item.id}>
                <AccordionItemTitle>
                  <Text element="h3" size="large" weight="bold">
                    {item.frontmatter.title}
                  </Text>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <Markdown>{item.body}</Markdown>
                </AccordionItemBody>
              </AccordionItem>
            )
          })}
        </Accordion>
      )}
    </Page2>
  )
}
